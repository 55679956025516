import { useZodFormFieldMultiple } from '~/components/zod/ZodForm';
import InputCheckbox, { InputCheckBoxProps } from '~/components/input/InputCheckbox';

export default function ZodFieldCheckboxMultiple({
  name,
  value,
  ...props
}: Omit<InputCheckBoxProps, 'checked' | 'onChange'> & {
  name: string;
  value: string;
}) {
  const [prefixed, zodValue, setValue] = useZodFormFieldMultiple(name);
  const checked = zodValue.includes(value);

  return (
    <InputCheckbox
      {...props}
      name={checked ? `${prefixed}[${zodValue.indexOf(value)}]` : undefined}
      checked={checked}
      value={value}
      onChange={(on) => {
        setValue(on ? [...zodValue, value] : zodValue.filter((v) => v !== value));
      }}
    />
  );
}
